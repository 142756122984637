import _toConsumableArray from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _createForOfIteratorHelper from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.reverse.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { chain, filter, find, last, map } from 'lodash';
import { defineComponent } from 'vue';
import { behandlerFilter } from '@/mixins/filters/behandler';
import HelpTooltip from '@/components/HelpTooltip.vue';
import HVMCellDirect from '@/components/hvm/HVMCellDirect.vue';
import { viewStateStore } from '@/state/viewStateStore';
import { german, isProd, roseDayjs } from '@/../../common-ui';
export default defineComponent({
  components: {
    HelpTooltip: HelpTooltip,
    HVMCellDirect: HVMCellDirect
  },
  props: {
    data: {
      type: Object,
      "default": null
    },
    pro: {
      type: Boolean,
      "default": function _default() {
        return false;
      }
    },
    valueToShow: {
      type: String,
      "default": function _default() {
        return 'punkte';
      }
    }
  },
  data: function data() {
    return {
      localeText: german,
      isProd: isProd,
      sortFieldMain: 'kch',
      sortDirectionMain: 'desc',
      // details stuff
      details: null,
      detailsProp: null,
      detailsTyp: null,
      detailsBehandler: null,
      partToShow: 'kch',
      showAllQuartalSummary: false,
      valueToggles: [{
        key: 'kchohneip',
        name: 'KCh ohne IP'
      }, {
        key: 'kbr',
        name: 'KBr',
        devonly: true
      } // { key: 'kch', name: 'KCh' },
      // { key: 'ip', name: 'IP' },
      // { key: 'kfo', name: 'KFO' },
      // { key: 'par', name: 'PAR' },
      ]
    };
  },
  computed: {
    valueTogglesFiltered: function valueTogglesFiltered() {
      var _this = this;

      return filter(this.valueToggles, function (t) {
        return !t.devonly || !_this.isProd;
      });
    },
    punktwerte: function punktwerte() {
      return this.data;
    },
    quartaleReverseNonEmpty: function quartaleReverseNonEmpty() {
      return chain(this.punktwerte.quartale).filter(function (q) {
        return roseDayjs(q.quartal.von).isBefore(roseDayjs());
      }).reverse().value();
    },
    quartaleNonEmpty: function quartaleNonEmpty() {
      return chain(this.punktwerte.quartale).filter(function (q) {
        return roseDayjs(q.quartal.von).isBefore(roseDayjs());
      }).value();
    },
    allYear: function allYear() {
      var qs = map(this.punktwerte.quartale, function (x) {
        return x.quartal;
      });
      var ymin = chain(qs).map(function (x) {
        return roseDayjs(x.von).year();
      }).min().value();
      var ymax = chain(qs).map(function (x) {
        return roseDayjs(x.von).year();
      }).max().value();

      if (ymin === ymax) {
        return (ymin === null || ymin === void 0 ? void 0 : ymin.toString()) || '-';
      }

      return "".concat(ymin, " - ").concat(ymax);
    }
  },
  watch: {
    punktwerte: {
      handler: function handler() {
        var vs = viewStateStore.getters.viewState;
        var fq;

        if (vs.quartal) {
          if (vs.quartal === 'all') {
            this.showAllQuartalSummary = true;
            return;
          }

          fq = find(this.punktwerte.quartale, function (q) {
            return q.quartal.key === vs.quartal;
          });
        } else {
          fq = find(this.punktwerte.quartale, function (q) {
            return roseDayjs().isBetween(q.quartal.von, q.quartal.bis);
          });
        }

        if (fq) {
          fq.quartal.show = true;
        } else {
          fq = last(this.punktwerte.quartale);

          if (fq) {
            fq.quartal.show = true;
          }
        }
      },
      immediate: true
    },
    partToShow: function partToShow() {
      void viewStateStore.dispatch.addToViewState({
        partToShow: this.partToShow
      });
    }
  },
  created: function created() {
    var vs = viewStateStore.getters.viewState;

    if (vs.partToShow) {
      this.partToShow = vs.partToShow;
    }
  },
  methods: {
    getBudgetCellData: function getBudgetCellData(q, kasse) {
      var _a;

      var r = (_a = q.referenz[this.partToShow]) === null || _a === void 0 ? void 0 : _a[kasse];
      return {
        main: r === null || r === void 0 ? void 0 : r.fallzahl,
        betrag: r === null || r === void 0 ? void 0 : r.betrag,
        punkte: r === null || r === void 0 ? void 0 : r.punkte,
        cases: r === null || r === void 0 ? void 0 : r.faelle,
        property: this.valueToShow,
        caseName: ['Fall', 'Fälle']
      };
    },
    getProCellData: function getProCellData(q, proPart, kasse) {
      var _a;

      var r = (_a = q.praxisRaw[this.partToShow + '_' + proPart]) === null || _a === void 0 ? void 0 : _a[kasse];
      return {
        // main: r?.fallzahl,
        betrag: r === null || r === void 0 ? void 0 : r.betrag,
        punkte: r === null || r === void 0 ? void 0 : r.punkte,
        cases: r === null || r === void 0 ? void 0 : r.fallzahl,
        property: this.valueToShow,
        caseName: ['Fall', 'Fälle'],
        light: true
      };
    },
    getVerbrauchCellData: function getVerbrauchCellData(q, kasse) {
      var _a;

      var r = (_a = q.verbrauch[this.partToShow]) === null || _a === void 0 ? void 0 : _a[kasse];
      return {
        main: r === null || r === void 0 ? void 0 : r.fallzahl,
        betrag: r === null || r === void 0 ? void 0 : r.betrag,
        punkte: r === null || r === void 0 ? void 0 : r.punkte,
        cases: r === null || r === void 0 ? void 0 : r.faelle,
        property: this.valueToShow,
        caseName: ['Fall', 'Fälle']
      };
    },
    getDifferenzCellData: function getDifferenzCellData(q, kasse) {
      var _a;

      var r = (_a = q.differenz[this.partToShow]) === null || _a === void 0 ? void 0 : _a[kasse];
      return {
        main: r === null || r === void 0 ? void 0 : r.fallzahl,
        betrag: r === null || r === void 0 ? void 0 : r.betrag,
        punkte: r === null || r === void 0 ? void 0 : r.punkte,
        property: this.valueToShow
      };
    },
    changePartToShow: function changePartToShow(key) {
      this.partToShow = key;
    },
    behandler: function behandler(id, field, addId, fallback) {
      return behandlerFilter.filters.behandler(id, field, addId, fallback);
    },
    sortChangedMain: function sortChangedMain(value) {
      console.log('sortchangedMain', value);
      this.sortFieldMain = value.field;
      this.sortDirectionMain = value.direction;
    },
    showDetails: function showDetails(details, prop, typ, behandler) {
      this.details = details;
      this.detailsProp = prop;
      this.detailsTyp = typ;
      this.detailsBehandler = behandler;
    },
    hideDetails: function hideDetails() {
      this.details = null;
    },
    toggleAll: function toggleAll() {
      this.showAllQuartalSummary = true;

      var _iterator = _createForOfIteratorHelper(this.punktwerte.quartale),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var q = _step.value;
          q.quartal.show = false;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      void viewStateStore.dispatch.addToViewState({
        quartal: 'all'
      });
    },
    toggleQuartal: function toggleQuartal(q) {
      this.showAllQuartalSummary = false;

      var _iterator2 = _createForOfIteratorHelper(this.punktwerte.quartale),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var x = _step2.value;

          if (x.quartal.key !== q.quartal.key) {
            x.quartal.show = false;
          } else {
            x.quartal.show = true;
            void viewStateStore.dispatch.addToViewState({
              quartal: x.quartal.key
            });
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    toggleQuartalDetails: function toggleQuartalDetails(q) {
      q.quartal.showDetails = !q.quartal.showDetails;
    },
    getPartToShow: function getPartToShow() {
      var extractedQuarters = [];

      var _iterator3 = _createForOfIteratorHelper(this.punktwerte.quartale),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var q = _step3.value;
          var praxis = q.praxis;
          var part = praxis[this.partToShow];
          extractedQuarters.push({
            quartal: q.quartal,
            data: part,
            arztanzahl: q.arztanzahl,
            kassen: q.kassen
          });
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      return extractedQuarters || [];
    },
    getKassenNoTotal: function getKassenNoTotal(kassen) {
      return kassen;
    },
    getKassen: function getKassen(kassen) {
      return [{
        key: 'total',
        name: 'Alle'
      }].concat(_toConsumableArray(this.getKassenNoTotal(kassen)));
    },
    getAlleKassen: function getAlleKassen() {
      var kassen = [];

      var _iterator4 = _createForOfIteratorHelper(this.quartaleNonEmpty),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var q = _step4.value;

          var _iterator5 = _createForOfIteratorHelper(q.kassen),
              _step5;

          try {
            var _loop = function _loop() {
              var qKassen = _step5.value;

              if (!find(kassen, function (k) {
                return k.key === qKassen.key;
              })) {
                kassen.push(qKassen);
              }
            };

            for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
              _loop();
            }
          } catch (err) {
            _iterator5.e(err);
          } finally {
            _iterator5.f();
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      return [{
        key: 'total',
        name: 'Alle'
      }].concat(kassen);
    },
    getBracketMatches: function getBracketMatches(b, quartalKey) {
      var part = this.getPartToShow();
      var results = [];
      var quartal = find(part, function (x) {
        return x.quartal.key === quartalKey;
      });

      if (quartal) {
        var kassen = this.getKassen(quartal.kassen);

        var _iterator6 = _createForOfIteratorHelper(kassen),
            _step6;

        try {
          for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
            var kasse = _step6.value;
            var kassendaten = quartal.data.brackets[kasse.key];
            var m = find(kassendaten, function (x) {
              return x.lower === b.lower;
            });
            results.push(Object.assign({
              kassekey: kasse.key,
              kasse: kasse.name
            }, m));
          }
        } catch (err) {
          _iterator6.e(err);
        } finally {
          _iterator6.f();
        }
      }

      return results;
    },
    getBracketFaelle: function getBracketFaelle(b, quartalKey) {
      var part = this.getPartToShow();
      var sum = 0;
      var quartal = find(part, function (x) {
        return x.quartal.key === quartalKey;
      });

      if (quartal) {
        var _iterator7 = _createForOfIteratorHelper(this.getKassenNoTotal(quartal.kassen)),
            _step7;

        try {
          for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
            var kasse = _step7.value;
            var kassendaten = quartal.data.brackets[kasse.key];
            var m = find(kassendaten, function (x) {
              return x.lower === b.lower;
            }); // console.log('getBracketFaelle XXXXXXX', kasse, kassendaten, m);

            sum += (m === null || m === void 0 ? void 0 : m.faelle) || 0;
          }
        } catch (err) {
          _iterator7.e(err);
        } finally {
          _iterator7.f();
        }

        return sum / quartal.arztanzahl;
      }

      return 0;
    },
    getBracketFaellePercentage: function getBracketFaellePercentage(b, quartalKey) {
      var part = this.getPartToShow();
      var quartal = find(part, function (x) {
        return x.quartal.key === quartalKey;
      });

      if (quartal) {
        var sum = this.getBracketFaelle(b, quartalKey);
        return sum / quartal.data.faelle * 100;
      }

      return -99;
    },
    bracketsWithValues: function bracketsWithValues(quartalKey) {
      var part = this.getPartToShow();
      var bwv = [];
      var quartal = find(part, function (x) {
        return x.quartal.key === quartalKey;
      });

      if (quartal) {
        var _iterator8 = _createForOfIteratorHelper(this.punktwerte.brackets),
            _step8;

        try {
          var _loop2 = function _loop2() {
            var b = _step8.value;
            var sum = 0;

            for (var kasse in quartal.data.brackets) {
              if (Object.prototype.hasOwnProperty.call(quartal.data.brackets, kasse)) {
                var kassendaten = quartal.data.brackets[kasse];
                var m = find(kassendaten, function (x) {
                  return x.lower === b.lower;
                }); // console.log('bracketsWithValues XXXXXXX', kasse, kassendaten, m);

                sum += (m === null || m === void 0 ? void 0 : m.inBracket) || 0;
              }
            }

            if (sum > 0) {
              bwv.push(b);
            }
          };

          for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
            _loop2();
          }
        } catch (err) {
          _iterator8.e(err);
        } finally {
          _iterator8.f();
        }
      }

      console.log('bracketsWithValues', quartalKey, quartal, bwv);
      return bwv;
    },
    gesamtAbweichung: function gesamtAbweichung(b) {
      var sum = 0;

      for (var kasse in b) {
        if (Object.prototype.hasOwnProperty.call(b, kasse)) {
          var kassendaten = b[kasse];

          if (kassendaten.punkte < 0) {
            sum += kassendaten.punkte;
          }
        }
      }

      return sum;
    }
  }
});