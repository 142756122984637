/* eslint-disable @typescript-eslint/unbound-method */
import { metricsApi } from '@/services/data';
import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import { currentYear, isDev, roseDayjs } from '../../../common-ui';
import { IHVMResultNordrhein, IHVMWestfalenLippeSettings, ITeam, IHVMResultWestfalenLippe } from '../../../types';
import { viewStateStore } from '@/state/viewStateStore';
import { isEqual, join, toNumber } from 'lodash';
import { IHVMResultBayern } from '@/../../types/ts';

Vue.use(Vuex);

type HVMResults = IHVMResultNordrhein | IHVMResultWestfalenLippe | IHVMResultBayern;

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    hvm: {} as HVMResults,
    loading: true,
    loadingBehandler: true,
    loadingVerlauf: true,
    ignorekzv: true,
    date: {
      startDate: isDev ? '2023-01-05' : roseDayjs(currentYear[0]).format('YYYY-MM-DD'),
      endDate: isDev ? '2023-01-05' : roseDayjs(currentYear[1]).format('YYYY-MM-DD'),
    },
    ageRange: [0, 100],
    teamSelected: undefined as undefined | ITeam,
    kzv: undefined as undefined | string,
    refType: undefined as undefined | string,
    error: undefined as undefined | string,
    wlbehandlerzeiten: [] as IHVMWestfalenLippeSettings['behandlerzeiten'],
  }),
  getters: {
    hvm(state) {
      return state.hvm;
    },
    loading(state) {
      return state.loading;
    },
    loadingBehandler(state) {
      return state.loadingBehandler;
    },
    loadingVerlauf(state) {
      return state.loadingVerlauf;
    },
    ignorekzv(state) {
      return state.ignorekzv;
    },
    date(state) {
      return state.date;
    },
    teamSelected(state) {
      return state.teamSelected;
    },
    ageRange(state) {
      return state.ageRange;
    },
    kzv(state) {
      return state.kzv;
    },
    refType(state) {
      return state.refType;
    },
    error(state) {
      return state.error;
    },
    wlbehandlerzeiten(state) {
      return state.wlbehandlerzeiten;
    },
  },
  mutations: {
    setHVM(state, punktwerte: HVMResults) {
      state.hvm = punktwerte;
    },
    setLoading(state, bool: boolean) {
      state.loading = bool;
    },
    setLoadingBehandler(state, bool: boolean) {
      state.loadingBehandler = bool;
    },
    setLoadingVerlauf(state, bool: boolean) {
      state.loadingVerlauf = bool;
    },
    setIgnoreKzv(state, bool: boolean) {
      state.ignorekzv = bool;
    },
    setDate(state, date) {
      state.date = date;
    },
    setTeamSelected(state, team) {
      state.teamSelected = team;
    },
    setAgeRange(state, ageRange) {
      state.ageRange = ageRange;
    },
    setKZV(state, kzv: string) {
      state.kzv = kzv;
    },
    setKZVInfo(state, kzvInfo: { kzv: string; refType?: string }) {
      state.kzv = kzvInfo.kzv;
      state.refType = kzvInfo.refType;
    },
    setRefType(state, refType) {
      state.refType = refType;
    },
    setError(state, error) {
      state.error = error;
    },
    setWLBehandlerzeiten(state, behandlerzeiten) {
      state.wlbehandlerzeiten = behandlerzeiten;
    },
  },
  actions: {
    async getData(context) {
      const { state, commit } = rootActionContext(context);
      console.log(`getdata start`, state);
      try {
        commit.setLoading(true);
        commit.setLoadingBehandler(true);
        commit.setLoadingVerlauf(true);
        commit.setError(null);
        const qp = {
          kzv: state.kzv,
          reftype: state.refType,
          von: state.date?.startDate,
          bis: state.date?.endDate,
          team: state.teamSelected?.id,
          altervon: `${state.ageRange[0]}`,
          alterbis: `${state.ageRange[1]}`,
          ignorekzv: state.ignorekzv ? 'true' : 'false',
        };
        await viewStateStore.dispatch.addToViewState(qp);
        qp.team = state.teamSelected?.id ? join(state.teamSelected?.mitglieder) : undefined;
        let hvm: HVMResults;
        if (state.kzv === '13' && state.refType === 'deprecated') {
          hvm = await metricsApi.hvm.getHVMNordrhein(qp);
        } else if (state.kzv === '13') {
          hvm = await metricsApi.hvm.getHVMNordrhein2(qp);
        } else if (state.kzv === '11' && state.refType === 'deprecated') {
          hvm = await metricsApi.hvm.getHVMBayern(qp);
        } else if (state.kzv === '11') {
          hvm = await metricsApi.hvm.getHVMBayern2(qp);
        } else if (state.kzv === '37' && state.refType === 'deprecated') {
          const p = {
            ...qp,
            refType: '',
            altervon: toNumber(qp.altervon),
            alterbis: toNumber(qp.alterbis),
            ignorekzv: state.ignorekzv,
            behandlerzeiten: state.wlbehandlerzeiten,
          };
          hvm = await metricsApi.hvm.getHVMWestfalenLippe(p);
        } else if (state.kzv === '37') {
          const p = {
            ...qp,
            refType: '',
            altervon: toNumber(qp.altervon),
            alterbis: toNumber(qp.alterbis),
            ignorekzv: state.ignorekzv,
            behandlerzeiten: state.wlbehandlerzeiten,
          };
          hvm = await metricsApi.hvm.getHVMWestfalenLippe2(p);
        } else {
          hvm = {} as HVMResults;
        }
        console.log('getdata end', hvm);
        commit.setHVM(hvm);
        commit.setLoading(false);

        // behandler & verlauf
        if (state.kzv === '13') {
          if (state.refType === 'deprecated') {
            const hvmBehandler = await metricsApi.hvm.getHVMNordrheinBehandler(qp);
            commit.setHVM({ ...state.hvm, behandler: hvmBehandler.behandler as any });
          }
          const hvmVerlauf = await metricsApi.hvm.getHVMNordrheinVerlauf(qp);
          commit.setHVM({ ...state.hvm, verlauf: hvmVerlauf.verlauf });
        } else if (state.kzv === '11' && state.refType === 'deprecated') {
          const hvmBehandler = await metricsApi.hvm.getHVMBayernBehandler(qp);
          commit.setHVM({ ...state.hvm, behandler: hvmBehandler.behandler as any });
        }
      } catch (err: any) {
        console.error(err);
        commit.setError(err.message);
      } finally {
        commit.setLoading(false);
        commit.setLoadingVerlauf(false);
        commit.setLoadingBehandler(false);
      }
    },
    async setKZV(context, data: { kzv: string; refType?: string }) {
      const { dispatch, commit, state } = rootActionContext(context);
      if (state.kzv !== data.kzv || state.refType !== data.refType) {
        commit.setKZVInfo(data);
        console.log('gd setkzv');
        await dispatch.getData();
      }
    },
    async setIgnoreKzv(context, data: boolean) {
      const { dispatch, commit, state } = rootActionContext(context);
      if (state.ignorekzv !== data) {
        commit.setIgnoreKzv(data);
        console.log('gd ignorekzv');
        await dispatch.getData();
      }
    },
    async setDate(context, data: { startDate: string; endDate: string }) {
      const { dispatch, commit, state } = rootActionContext(context);
      if (state.date.startDate !== data.startDate || state.date.endDate !== data.endDate) {
        commit.setDate(data);
        console.log('gd date');
        await dispatch.getData();
      }
    },
    async setTeamSelected(context, team: ITeam) {
      const { dispatch, commit, state } = rootActionContext(context);
      if (state.teamSelected?.id !== team.id) {
        commit.setTeamSelected(team);
        console.log('gd date');
        await dispatch.getData();
      }
    },
    async setAgeRange(context, ageRange: number[]) {
      const { dispatch, commit, state } = rootActionContext(context);
      if (state.ageRange[0] !== ageRange[0] || state.ageRange[1] !== ageRange[1]) {
        commit.setAgeRange(ageRange);
        console.log('gd ageRange');
        await dispatch.getData();
      }
    },
    async setWLBehandlerzeiten(context, behandlerzeiten: IHVMWestfalenLippeSettings['behandlerzeiten']) {
      const { dispatch, commit, state } = rootActionContext(context);
      if (!isEqual(state.wlbehandlerzeiten, behandlerzeiten)) {
        commit.setWLBehandlerzeiten(behandlerzeiten);
        console.log('gd behandlerzeiten');
        await dispatch.getData();
      }
    },
  },
});

export const hvmStore = store;
