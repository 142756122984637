import { hvmStore } from '@/state/hvmStore';
import { roseDayjs } from '@rose/common-ui';

export function setStoreFromParams(routeParams?: any) {
  let kzvInfo: { kzv: string; refType: string; orig: string } | undefined = undefined;
  if (routeParams) {
    if (routeParams?.kzv) {
      let ki: string = routeParams.kzv;
      if (routeParams.reftype) {
        ki = `${ki}-${routeParams.reftype}`;
      }
      kzvInfo = setKZV(ki);
    }

    if (routeParams?.ignorekzv) {
      hvmStore.commit.setIgnoreKzv(routeParams.ignorekzv === 'true');
    }

    if (routeParams?.von && routeParams?.bis) {
      hvmStore.commit.setDate({
        startDate: roseDayjs(routeParams.von as string).format('YYYY-MM-DD'),
        endDate: roseDayjs(routeParams.bis as string).format('YYYY-MM-DD'),
      });
    }

    if (routeParams?.altervon && routeParams?.alterbis) {
      hvmStore.commit.setAgeRange([routeParams.altervon, routeParams.alterbis]);
    }
  }
  return kzvInfo;
}

export function setKZV(kzvInfo: string) {
  console.log('setKZV', kzvInfo);
  const [kzv, refType] = kzvInfo.split('-');
  hvmStore.commit.setKZVInfo({ kzv, refType });
  return { kzv, refType, orig: kzvInfo };
}
